<template>
  <div>
    <div class="wrap" v-if="platform.isPC">
      <div class="topbox">
        <ul>
          <img :src="info.logoUrl" alt="" />
        </ul>
        <ul>
          <p class="title">{{ $t("index.关于牧德") }}</p>
          <li @click="goto('/aboutus?view=0')">
            {{ $t("index.公司介绍") }}
          </li>
          <li @click="goto('/aboutus?view=1')">
            {{ $t("index.企业文化") }}
          </li>
          <li @click="goto('/aboutus?view=2')">
            {{ $t("index.发展历程") }}
          </li>
        </ul>
        <ul>
          <p class="title">{{ $t("index.媒体中心") }}</p>
          <li @click="goto('/media/news')">
            {{ $t("index.新闻资讯") }}
          </li>
          <li @click="goto('/media/productVideo')">
            {{ $t("index.产品视频") }}
          </li>
          <li @click="goto('/media/useCases')">
            {{ $t("index.使用案例") }}
          </li>
        </ul>
        <ul>
          <p class="title">{{ $t("index.服务与支持") }}</p>
          <li @click="goto('/teach/teachList')">
            {{ $t("index.售后指南") }}
          </li>
          <li @click="goto('/consult')">
            {{ $t("index.服务咨询") }}
          </li>
          <li @click="goto('/service/download/app')">
            {{ $t("index.软件下载") }}
          </li>
          <li @click="goto('/service/instructionsList')">
            {{ $t("index.说明书") }}
          </li>
        </ul>
        <ul>
          <p class="title">{{ $t("index.联系我们") }}</p>
          <li>
            <i class="el-icon-phone"></i>
            <div>
              {{ info.contact }}
            </div>
          </li>
          <li v-if="false">
            <img src="@assets/imgs/spidertwoexplainM/address_icon.png" />
            {{ $t("index.北京市，丰台区，汉威国际4区7号楼8层") }}
          </li>
          <li>
            <i class="el-icon-message"></i>
            <div>
              {{ info.email }}
            </div>
          </li>
          <li>
            <div class="wximage">
              <img
                src="@assets/imgs/footerM-icon/wechat-fill.png"
                class="image"
              />
              <img :src="info.wxUrl" alt="" class="wxcode" />
            </div>
            <img
              src="@assets/imgs/footerM-icon/facebook-fill.png"
              class="image"
              @click="goto(info.facebookUrl)"
            />
            <img
              src="@assets/imgs/footerM-icon/instagram-fill.png"
              class="image"
              @click="goto(info.douyinUrl)"
            />
          </li>
        </ul>
      </div>
      <div class="copyright">
        <p v-if="isCN">
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >京ICP备18012722号-1</a
          >
        </p>
        &nbsp;|&nbsp;
        <div>Copyright © 牧德科技. 2024</div>
        &nbsp;|&nbsp;
        <div>All Rights Reserved. Terms &amp; conditions</div>
      </div>
    </div>
    <div class="mwrap" v-else>
      <el-collapse v-model="activeName" accordion class="fff">
        <el-collapse-item title="关于牧德" name="1" class="fff">
          <template #title>
            {{ $t("index.关于牧德") }}
          </template>
          <ul>
            <li @click="goto('/aboutus?view=0')">
              {{ $t("index.公司介绍") }}
            </li>
            <li @click="goto('/aboutus?view=1')">
              {{ $t("index.企业文化") }}
            </li>
            <li @click="goto('/aboutus?view=2')">
              {{ $t("index.发展历程") }}
            </li>
          </ul>
        </el-collapse-item>
        <el-collapse-item title="媒体中心" name="2">
          <template #title>
            {{ $t("index.媒体中心") }}
          </template>
          <ul>
            <li @click="goto('/media/news')">
              {{ $t("index.新闻资讯") }}
            </li>
            <li @click="goto('/media/productVideo')">
              {{ $t("index.产品视频") }}
            </li>
            <li @click="goto('/media/useCases')">
              {{ $t("index.使用案例") }}
            </li>
          </ul>
        </el-collapse-item>
        <el-collapse-item title="服务与支持" name="3">
          <template #title>
            {{ $t("index.服务与支持") }}
          </template>
          <ul>
            <li @click="goto('/teach/teachList')">
              {{ $t("index.售后指南") }}
            </li>
            <li @click="goto('/consult')">
              {{ $t("index.服务咨询") }}
            </li>
            <li @click="goto('/service/download/app')">
              {{ $t("index.软件下载") }}
            </li>
            <li @click="goto('/service/instructionsList')">
              {{ $t("index.说明书") }}
            </li>
          </ul>
        </el-collapse-item>
      </el-collapse>
      <ul class="callme">
        <li class="wetitle">{{ $t("index.联系我们") }}</li>
        <li class="small">
          <img src="@assets/imgs/spidertwoexplainM/phone_icon.png" />
          {{ info.contact }}
        </li>
        <li class="small">
          <img src="@assets/imgs/spidertwoexplainM/enterprise_icon .png" />
          {{ $t("index.周一至周日") }}
          :9:00-18:30
        </li>
        <li class="small">
          <img src="@assets/imgs/spidertwoexplainM/address_icon.png" />
          {{ $t("index.北京市，丰台区，汉威国际4区7号楼8层") }}
        </li>
        <li class="small">
          <img src="@assets/imgs/spidertwoexplainM/mail_icon.png" />
          {{ info.email }}
        </li>
<!--        <br v-if="isCN" />-->
        <li class="wetitle" v-if="isCN">微信公众号</li>
        <li v-if="isCN">
          <img :src="info.wxUrl" class="wetitle-logo" />
        </li>
        <br v-if="isCN" />
        <li v-if="isCN" class="small">第一时间获得最新动态</li>
        <br />
        <li class="relation">
<!--          <img
            v-if="isCN"
            src="@assets/imgs/spidertwoexplainM/WeChat_icon.png"
          />-->
<!--          <img src="@assets/imgs/spidertwoexplainM/whatsapp_icon.png" />-->
          <img src="@assets/imgs/spidertwoexplainM/facebook_icon.png" @click="goto(info.facebookUrl)" />
          <img src="@assets/imgs/spidertwoexplainM/ins_icon.png" @click="goto(info.douyinUrl)" />
        </li>
      </ul>
      <div v-if="false">
        <div class="copyrightm">
          <p>Copyright © 牧德科技. 2024 - All Rights Reserved.</p>
          <p>京ICP备18012722号 隐私声明</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { goto } from "@utils/goto";
import { mapState } from "vuex";
import platform from "@utils/platform";
import RouteMap, { activeRoute } from "./Header/RouteMap";
import { getInfo } from "@/service/api";

export default {
  computed: {
    ...mapState(["lang"]),
    isCN() {
      return this.lang === "zh-CN";
    },
  },
  data() {
    return {
      info: {},
      platform,
      curNav: activeRoute,
      spreadNavIdx: 0,
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
      visible5: false,
      visible6: false,
    };
  },
  mounted() {
    this.Info();
  },
  methods: {
    goto,
    async Info() {
      this.info = (await getInfo()) || {};
      // console.log(this.info);
    },
    setSpreadNavIdx(idx) {
      console.log(idx);
      //  2暂时没有菜单
      // if ([2].includes(idx)) return;
      // if (idx === this.spreadNavIdx) {
      //   this.spreadNavIdx = "";
      // } else {
      //   this.spreadNavIdx = idx;
      // }
    },
    onNavClick(navIdx, nav2Idx = 0) {
      this.curNav = `${navIdx}_${nav2Idx}`;
      const url = RouteMap[this.curNav];
      console.log(url);
      // if (url) {
      //   goto(url);
      // }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";

img {
  vertical-align: top;
}

.copyrightm {
  background-color: #fff;
  padding: px2rem(10px) px2rem(20px);
  font-size: px2rem(11px);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #101010;
  line-height: px2rem(18px);
  text-transform: uppercase;
  border: none;
}
.mwrap {
  font-size: 0;
  width: 100vw;
  height: auto;
  padding: 0 px2rem(20px) px2rem(20px) px2rem(20px);
  background-color: #000000;
  color: white;

  .popover {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin: px2rem(20px) 0 px2rem(5px);
    div {
      width: 15%;

      img {
        width: 100%;
      }
    }
  }
  .nav-item {
    // margin-top: px2rem(5px);
    // line-height: px2rem(70px);
    width: 100%;
    // 展开
    &.active {
      height: auto;
      .sub-nav {
        display: block;
        border: none;
      }
    }
    .nav-item-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: px2rem(15px);
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: 400;
      line-height: px2rem(40px);
      height: px2rem(60px);
      // padding-bottom: px2rem(3px);
      border-bottom: px2rem(1px) solid #9f9f9f;
      i {
        width: px2rem(25px);
        height: px2rem(25px);
        background: url(@assets/imgs/spidertwoexplainM/develop_icon.png)
          no-repeat center;
        background-size: 60%;
      }
    }
    .sub-nav {
      display: none;
      // padding-bottom: px2rem(20px);
      font-size: px2rem(14px);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      // line-height: px2rem(20px);
      .sub-nav-item {
        font-size: px2rem(16px);
        font-family: PingFang-SC-Bold, PingFang-SC;
        line-height: px2rem(30px);
        margin-top: px2rem(8px);
        // height: px2rem(50px);
        &.active {
          color: #e8e354;
        }
        &:nth-child(n + 2) {
          font-weight: 400;
        }
      }
    }
  }
  .callme {
    text-align: center;
    font-size: px2rem(12px);

    .wetitle {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      margin: px2rem(10px) 0;
      font-size: 16px;
    }
    .small {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.6;
      margin: px2rem(5px);

      img {
        width: px2rem(14px);
        // margin-top: px2rem(20px);
      }
    }
    .relation {
      margin: px2rem(5px) 0;
      img {
        margin: 0 px2rem(7px);
      }
    }
  }
  li {
    margin-left: 20px;
  }
}
.wrap {
  background-color: rgba($color: #000000, $alpha: 1);
  padding: 40px 100px 20px;
  color: #fff;
  position: relative;
  z-index: 10;
  .topbox {
    position: relative;
    display: flex;
    justify-content: space-between;
    ul {
      max-width: 200px;
      li {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin: 10px 0;
        position: relative;
        // right: 14px;
        i {
          margin-right: 10px;
          color: red;
        }
        .image {
          width: 26px;
          margin: 10px 10px;
        }
        .wximage:hover {
          .wxcode {
            display: block;
          }
        }
        .wximage {
          position: relative;
          .wxcode {
            width: 100px;
            position: absolute;
            top: -99px;
            left: -25px;
            display: none;
            // transform: translate(50%, 50%);
          }
        }
        // img:hover {
        //   opacity: 0.6;
        // }
      }
      li:hover {
        color: red;
        cursor: pointer;
      }
    }
    .title {
      // padding-left: 5px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 60px;
    }
  }
  .copyright {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    // position: absolute;
    font-family: HelveticaNeueLTPro-Roman;
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.6);
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;

    a {
      color: rgba(255, 255, 255, 0.6);
      &:hover {
        color: orange;
      }
    }
  }
}

.wetitle-logo {
  width: 80px;
}
</style>
<style>
.el-collapse-item__header {
  background-color: #000000;
  font-size: 16px;
  color: white;
}
.el-collapse-item__content {
  background-color: #000000;
  font-size: 16px;
  color: white;
  line-height: 35px;
  padding-bottom: 10px;
  /*display: grid;*/
  /*grid-template-columns: repeat(3, 1fr);*/
  /*gap: 20px;*/
  /*text-align: center;*/
}
</style>
