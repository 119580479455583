import { SET_LANG, SET_PROFILE } from './type'
import { getLang, getProfile } from '@service/api'
import i18n from '@/locale'

export default {
    async setLang({ commit }, lang) {
        try {
            if (!lang) {
                // lang = localStorage.getItem('gowow_lang')
                // if (!lang) {
                //     ({ lang } = await getLang() || {})
                // }
                ({ lang } = await getLang() || {})
            }
            // lang = 'zh-CN'
            i18n.locale = lang
            commit(SET_LANG, lang)
            // localStorage.setItem("MODE_locallanguage", lang);
            // localStorage.setItem('gowow_lang', lang)
        } catch (e) {
            commit(SET_LANG, 'zh-CN')
        }
    },
    async setProfile({ commit }, profile) {
        try {
            if (!profile) {
                profile = await getProfile() || {}
            }
            commit(SET_PROFILE, profile)
        } catch (e) {
        }
    }
}
