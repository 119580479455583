<template>
  <div class="m-header">
    <div class="m-header-content" ref="hbox">
      <div class="header-right">
        <i
            :class="[
            'icon-nav',
            m_nav_show ? 'el-icon-s-unfold' : 'el-icon-s-fold',
          ]"
            v-if="false"
        ></i>
      </div>
      <p>
        <img :src="info.logoUrl" @click="onBrandClick" />
      </p>
      <i class="el-icon-user-solid" v-if="false"></i>
    </div>
<!--    <div :class="['nav', { show: m_nav_show }]">
      <div class="nav-item">
        <div class="nav-item-content" @click="onNavClick(0, 0)">
          <span>{{ $t("index.猎户座") }}</span>
          <i></i>
        </div>
      </div>
      &lt;!&ndash; <div :class="['nav-item', 'item-style', { active: spreadNavIdx === 1 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(1)">
          <span> {{ $t("index.生活方式") }}</span>
          <i></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '1_1' }]"
            @click="onNavClick(1, 1)"
          >
            {{ $t("lifeStyle.摩友圈") }}
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '1_2' }]"
            @click="onNavClick(1, 2)"
          >
            {{ $t("lifeStyle.俱乐部") }}
          </div>
        </div>
      </div> &ndash;&gt;
      <div class="nav-item">
        <div class="nav-item-content" @click="onNavClick(1)">
          <span> {{ $t("index.vr看车") }}</span>
          <i></i>
        </div>
      </div>
      <div :class="['nav-item', { active: spreadNavIdx === 7 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(7)">
          <span>{{ $t("index.关于我们") }}</span>
          <i id="add"></i>
        </div>
        <div class="sub-nav" v-if="false">
          <div
              :class="['sub-nav-item', { active: curNav === '7_1' }]"
              @click="onNavClick(7, 1)"
          >
            {{ $t("lifeStyle.格物电动摩托车") }}
          </div>
          <div
              :class="['sub-nav-item', { active: curNav === '7_2' }]"
              @click.stop="() => onNavClick(7, 2)"
          >
            {{ $t("partner.合作伙伴") }}
          </div>
          <div :class="['sub-nav-item', { active: curNav === '3_1' }]">
            {{ $t("index.设计理念") }}
          </div>
          <div :class="['sub-nav-item', { active: curNav === '3_2' }]">
            {{ $t("index.品牌宣言") }}
          </div>
          <div :class="['sub-nav-item', { active: curNav === '3_3' }]">
            {{ $t("index.产品灵感") }}
          </div>
        </div>
      </div>
      <div class="nav-bottom">
        <div class="nav-item nav-item-login" v-if="!isLogin" @click="onLogin">
          {{ $t("index.登录") }}
        </div>
        &lt;!&ndash; <div
              v-if="isLogin"
              :class="['user-item', 'nav-item', { active: curNav1 === '4' }]"
              @click="() => onNavClick(4)"
            >
              <i class="el-icon-user"></i>
            </div> &ndash;&gt;
      </div>
    </div>-->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { goto } from "@utils/goto";
import { getMenu, getInfo, selectPersonInfo } from "@/service/api";
import RouteMap, { activeRoute } from "./RouteMap";

export default {
  computed: {
    ...mapState(["lang", "profile"]),
    ...mapGetters(["isLogin"]),
    curNav1() {
      if (/^([^_]*)[_]/.test(this.curNav)) {
        return RegExp.$1;
      }
      return undefined;
    },
  },
  data() {
    return {
      clickId: "",
      info: {},
      menu: {},
      activeNavItem: null,
      show: false,
      curNav: activeRoute,
      ModeToken: localStorage.getItem("MODE_accessToken"),
      lang_options: [
        { value: "en-US", label: "US" },
        { value: "zh-CN", label: "CN" },
      ],
    };
  },
  mounted() {
    this.Info();
    this.fetchList();
    this.clickId = localStorage.getItem("clickMenuId");
    // console.log(this.clickId);
    if (localStorage.getItem("MODE_locallanguage")) {
      setTimeout(() => {
        this.setLang(localStorage.getItem("MODE_locallanguage"));
      }, 600);
    } else {
      if (navigator.language != "zh-CN") {
        localStorage.setItem("MODE_locallanguage", "en-US");
        window.location.reload();
      }
    }
  },
  methods: {
    ...mapActions(["setLang"]),
    onNavClick(navIdx, nav2Idx = 0) {
      this.curNav = `${navIdx}_${nav2Idx}`;
      const url = RouteMap[this.curNav];
      console.log(url);
      localStorage.setItem("clickMenuId", 99);
      if (url) {
        goto(url);
      }
    },
    onChangeLang(lang) {
      // this.setLang(lang);
      localStorage.setItem("MODE_locallanguage", lang);
      window.location.reload();
    },
    onLogin() {
      goto("/login");
      localStorage.setItem("clickMenuId", 99);
    },
    onBrandClick() {
      goto("/");
    },
    gotoProductDetail(path, id, prodectId) {
      if (path == "") {
        alert(this.$t("index.敬请期待"));
        localStorage.removeItem("clickMenuId");
      } else {
        goto(path + "&menuId=" + id);
        localStorage.setItem("clickMenuId", prodectId);
      }
    },
    gotoroute(path, prodectId) {
      localStorage.setItem("clickMenuId", prodectId);
      if (path) {
        goto(path);
      }
    },
    showPopup() {
      this.show = true;
      this.$refs.form && this.$refs.form.reset();
    },
    closePopUp() {
      this.show = false;
    },
    async Info() {
      this.menu = (await getMenu()) || {};
      this.info = (await getInfo()) || {};
      console.log(this.menu);
    },
    async fetchList() {
      try {
        await selectPersonInfo();
      } catch (res) {
        this.ModeToken = false;
      }
    },
    showSubMenu(navItemId) {
      this.activeNavItem = navItemId;
    },
    hideSubMenu() {
      this.activeNavItem = null;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.m-header {
  position: fixed;
  z-index: 11;
  top: 0;
  overflow: visible;
  width: 100%;

  .m-header-content {
    position: relative;
    z-index: 1000;
    @extend .flex-between;
    padding: 0 px2rem(10px);
    height: px2rem(40px);
    background-color: #000000;
    // color: #ffffff;
    font-size: px2rem(22px);

    p {
      width: fill-available;
      //text-align: center;
      display: flex;
      align-items: center; /* 水平居中 */
      justify-content: center;

      img {
        width: px2rem(80px);
      }
    }

    .header-right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .icon-nav {
        font-size: px2rem(22px);
      }
    }
  }

  #add {
    background: url(~@assets/imgs/compont/icon_add.png) no-repeat center;
  }

  .nav {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: calc(100vh - 100%);
    padding: px2rem(10px) px2rem(15px) px2rem(20px);
    z-index: 999;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    transform: translate3d(0, -100%, 0);
    transition: transform 0.3s;

    &.show {
      transform: translate3d(0, 0, 0);
      background-color: white;
    }

    .nav-item {
      margin-top: px2rem(7px);
      line-height: px2rem(70px);
      // 展开
      &.active {
        #add {
          background-image: url(~@assets/imgs/compont/icon_remove.png);
        }

        .sub-nav {
          display: block;
        }
      }

      .nav-item-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: px2rem(17px);
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        line-height: px2rem(40px);
        height: px2rem(50px);
        // padding-bottom: px2rem(3px);
        border-bottom: 1px solid #000000;

        i {
          width: px2rem(25px);
          height: px2rem(25px);
          background-size: 60%;
          background: url(~@assets/imgs/compont/icon_right.png) no-repeat center;
          color: #000;
        }
      }

      .sub-nav {
        display: none;
        padding-top: px2rem(11px);
        padding-bottom: px2rem(38px);
        font-size: px2rem(16px);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: px2rem(25px);

        .sub-nav-item {
          &.active {
            color: #e8e354;
          }

          &:nth-child(n + 2) {
            margin-top: px2rem(8px);
          }
        }
      }
    }

    .nav-bottom {
      position: absolute;
      bottom: px2rem(40px);
      left: px2rem(15px);
      right: px2rem(15px);
      padding: px2rem(12px) px2rem(12px) 0;
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
      border-top: 1px solid #000;

      .nav-item-login {
        margin-top: px2rem(37px);
        font-size: px2rem(17px);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: px2rem(24px);
      }
    }
  }
}
</style>
