<template>
  <div>
    <HeaderPc v-if="platform.isPC" />
    <HeaderM v-else ref="header" />
  </div>
</template>
 
<script>
import platform from "@utils/platform";
import HeaderM from "./HeaderM.vue";
import HeaderPc from "./HeaderPC.vue";
import { mapActions } from "vuex";

export default {
  components: { HeaderM, HeaderPc },
  data() {
    return { platform };
  },
  mounted() {
    this.setLang();
    // this.setProfile();
  },
  methods: {
    ...mapActions(["setLang", "setProfile"]),
  },
};
</script>
