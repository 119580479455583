const RouteMap = {};
RouteMap['0_0'] = '/product';
RouteMap['0_3'] = '/brandNews';
RouteMap['1_1'] = '/motoCircle';
RouteMap['3_0'] = '/brandConcept?anchor=introduction';
RouteMap['3_1'] = '/brandConcept?anchor=concept';
RouteMap['3_2'] = '/brandConcept?anchor=manifesto';
RouteMap['3_3'] = '/brandConcept?anchor=insperation';
RouteMap['4_0'] = '/personal';
RouteMap['7_1'] = '/ebike';
RouteMap['7_2'] = '/partner';

const pathname = location.pathname;
const url = location.href;
const urlMaps = {
  '/motoCircle': '1_1',
  '/motoCircleDetail': '1_1',
  '/motoCirclePost': '1_1',
  '/product': '0_0',
  '/brandNews': '0_3',
  '/brandNewsDetail': '0_3',
  '/brandConcept?anchor=introduction': '3_0',
  '/brandConcept?anchor=concept': '3_1',
  '/brandConcept?anchor=manifesto': '3_2',
  '/brandConcept?anchor=insperation': '3_3',
  '/personal': '4_0',
  '/ebike': '7_1',
  '/partner': '7_2',

};
const matchUrl = () => {
  for (let k in urlMaps) {
    if (url.indexOf(k) > -1) {
      return urlMaps[k];
    }
  }
};
export const activeRoute = matchUrl() || RouteMap[pathname];

export default RouteMap;
