import axios from 'axios';
import { stringify } from 'qs';
import { goto } from './goto';
import { setAccessToken, getAccessToken, apiUrl } from './token';
import { refreshToken } from '../service/api';

// const { NODE_ENV = '' } = process.env;
// const baseURL = NODE_ENV === 'development' ? '/API' : '';
// const baseURL = 'http://192.168.1.39:9002/';
// const baseURL = 'https://api.modehoist.com';
const baseURL = apiUrl;
// 白名单，判断登录不拦截
const whiteList = ['/api/user/profile', '/api/user/register', '/api/user/password/change'];
// 黑名单，拦截，优先级高于whiteList,主要是个人相关的页面
const blackPageList = ['/orderAdd', '/orderDetail', '/personal', '/editPersonal'];

const instance = axios.create({
  baseURL,
  withCredentials: true,
});
instance.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';

instance.interceptors.request.use(
  (config) => {
    const ctReg = /application\/x-www-form-urlencoded/i;
    if (ctReg.test(config.headers['Content-Type'])) {
      config.data = stringify(config.data || '{}');
    }
    // 设置header accessToken
    const accessToken = localStorage.getItem("MODE_accessToken");
    config.headers['Authorization'] = `Bearer ${accessToken}`;
    config.headers['Language'] = localStorage.getItem("MODE_locallanguage") || navigator.language;
    return config;
  },
  (error) => Promise.reject(error),
);

const dealUnlogin = async (response) => {
  try {
    const accessToken = getAccessToken();
    if (accessToken) {
      const { accessToken: new_accessToken } = (await refreshToken({ accessToken })) || {};
      setAccessToken(new_accessToken);
      // 继续请求
      const { url, method, baseURL, data, params } = response.config;
      const _data = data ? JSON.parse(data) : data;
      const res = instance.axios({
        baseURL,
        url,
        method,
        data: _data,
        params,
      });
      return res;
    }
  } catch (e) { }
  if (blackPageList.includes(location.pathname) || whiteList.indexOf(response.config.url) === -1) {
    const backpage = location.pathname !== '/login' ? location.pathname : '';
    goto(`/login?backpage=${backpage}`);
  }
};

const successHandle = async (response) => {
  if (response.status >= 200 && response.status < 300) {
    const res = response.data;
    // 登录成功，设置token
    if (response.config.url === '/V1/person/login') {
      if (res.code === 0) {
        const { accessToken = '' } = res.data || {};
        setAccessToken(accessToken);
      }
    }
    return res; // 二进制之类
  }
  return Promise.reject(response);
};
const errorHandle = async (error) => {
  if (error.response && error.response.status) {
    switch (error.response.status) {
      case 401:
        {
          const res = await dealUnlogin(error.response);
          if (res) {
            return Promise.resolve(res);
          }
        }
        break;
      default:
        break;
    }
    return Promise.reject(error.response);
  }
};

instance.interceptors.response.use(successHandle, errorHandle);

export default instance;
